import styled, { css } from 'styled-components';
import { media } from 'utils';

export const ArrowButton = styled.button`
  height: 100%;
  width: 70px;
  background: linear-gradient(
    ${({ isLeft }) => (isLeft ? 'to left' : 'to right')},
    transparent 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  border: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: ${({ isLeft }) => (isLeft ? 'flex-start' : 'flex-end')};
  align-items: center;
  visibility: ${({ isDisabled }) => (isDisabled ? 'hidden' : 'visible')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0' : '1')};
  transition: visibility .2s ease, opacity .2s ease;

  &:focus {
    outline: none;
  }

  img {
    display: block;
    max-width: 30px;
    transform: ${({ isLeft }) => (isLeft ? 'rotate(180deg)' : 'none')};
    margin-${({ isLeft }) => (isLeft ? 'left' : 'right')}: 10px;
  }

  ${media.large(css`
    width: 100px;

    img {
      max-width: 40px;
    }
  `)};
`;
