import styled, { css } from 'styled-components';
import { media } from 'utils/mediaQueries';

import Img from 'gatsby-image';

export const Image = styled(Img)`
  height: 300px;

  ${media.large(css`
    height: 500px;
  `)}
`;
