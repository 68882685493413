import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Observer from '@researchgate/react-intersection-observer';
import { BounceLoader } from 'components/common/BounceLoader/BounceLoader';
import { LoaderWrapper } from './InfiniteScrollStyles';

export class InfiniteScroll extends Component {
  state = {
    itemsToDisplay: this.props.initialItemsCount,
  };

  handleObserverChange = ({ isIntersecting }, unobserve) => {
    const { initialItemsCount, itemsCount } = this.props;
    const { itemsToDisplay } = this.state;
    const shouldUnobserve = itemsToDisplay > itemsCount;

    if (!isIntersecting) return null;

    this.setState((props) => ({
      itemsToDisplay: props.itemsToDisplay + initialItemsCount,
    }));

    return shouldUnobserve ? unobserve() : null;
  };

  render() {
    const { itemsCount, renderItems } = this.props;
    const { itemsToDisplay } = this.state;
    const shouldDisableLoading = itemsToDisplay > itemsCount;

    return (
      <>
        {renderItems(itemsToDisplay)}
        <Observer
          disabled={shouldDisableLoading}
          onChange={this.handleObserverChange}
          rootMargin="0% 0% -25%"
          threshold={[0, 0.5, 1]}
        >
          <LoaderWrapper isDisabled={shouldDisableLoading}>
            <BounceLoader />
          </LoaderWrapper>
        </Observer>
      </>
    );
  }
}

InfiniteScroll.propTypes = {
  initialItemsCount: PropTypes.number,
  itemsCount: PropTypes.number.isRequired,
  renderItems: PropTypes.func.isRequired,
};

InfiniteScroll.defaultProps = {
  initialItemsCount: 2,
};
