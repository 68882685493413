import styled, { css } from 'styled-components';
import { media } from 'utils';

export const columnWidth = css`
  ${({
    theme: {
      layout: { contentWidth, gapBoxesDesktop },
    },
  }) => `minmax(0, calc((${contentWidth} - (${gapBoxesDesktop} * 2)) / 3))`}
`;

export const GridBoxes = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: ${({ theme }) => theme.layout.gapBoxesMobile};

  ${({ itemsCount }) =>
    media.large(css`
      grid-template: auto / repeat(${itemsCount}, ${columnWidth});
      justify-content: center;
      grid-gap: ${({ theme }) => theme.layout.gapBoxesDesktop};
    `)};
`;
