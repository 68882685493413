import React from 'react';
import PropTypes from 'prop-types';

import { FluidImagePropTypes } from 'types';

import { ImageBox } from 'components/common/ImageBox/ImageBox';
import { GridWrapper } from './ImageBoxesStyles';

export const ImageBoxes = ({ isMultiRow, items }) => (
  <GridWrapper isMultiRow={isMultiRow} itemsCount={items.length}>
    {items.map(({ description, image, name }) => (
      <ImageBox
        key={name}
        description={description}
        image={image?.childImageSharp?.fluid || image}
        isCircular
        isFluid={!!image?.childImageSharp?.fluid}
        name={name}
      />
    ))}
  </GridWrapper>
);

export const ImageBoxesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string]),
    name: PropTypes.string,
  })
);

ImageBoxes.propTypes = {
  isMultiRow: PropTypes.bool,
  items: ImageBoxesPropTypes.isRequired,
};

ImageBoxes.defaultProps = {
  isMultiRow: false,
};
