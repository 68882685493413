import React from 'react';
import PropTypes from 'prop-types';

import { Content } from './HTMLContentStyles';

export const HTMLContent = ({ content, isHTML }) => {
  const contentProps = isHTML
    ? { dangerouslySetInnerHTML: { __html: content } }
    : { children: content };

  return <Content {...contentProps} />;
};

HTMLContent.propTypes = {
  content: PropTypes.node.isRequired,
  isHTML: PropTypes.bool,
};

HTMLContent.defaultProps = {
  isHTML: false,
};
