import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import Carousel from 'nuka-carousel';

import { FluidImagePropTypes } from 'types';

import { Arrow } from 'components/common/Arrow/Arrow';
import { Fade } from 'components/common/Animations';
import { Bullets } from 'components/common/Bullets/Bullets';
import { Image } from './SliderStyles';

export const Slider = ({ images }) => (
  <Fade>
    <Carousel
      heightMode="first"
      renderBottomCenterControls={({ currentSlide, goToSlide, slideCount }) => (
        <Bullets
          activeIndex={currentSlide}
          count={slideCount}
          onClick={goToSlide}
        />
      )}
      renderCenterLeftControls={({ currentSlide, previousSlide }) => (
        <Arrow
          alt="Previous"
          isDisabled={currentSlide === 0}
          isLeft
          onClick={previousSlide}
        />
      )}
      renderCenterRightControls={({ currentSlide, nextSlide, slideCount }) => (
        <Arrow
          alt="Next"
          isDisabled={currentSlide === slideCount - 1}
          onClick={nextSlide}
        />
      )}
    >
      {images.map(({ image }, index) => (
        <Image
          key={image.id || image}
          alt={`Slider image ${index + 1}`}
          as={image?.childImageSharp?.fluid ? Image : 'img'}
          fluid={image?.childImageSharp?.fluid}
          src={image}
        />
      ))}
    </Carousel>
  </Fade>
);

export const SliderPropTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string]),
    })
  ).isRequired,
};

Slider.propTypes = SliderPropTypes;

export const sliderFragment = graphql`
  fragment SliderFragment on MarkdownRemark {
    frontmatter {
      images {
        image {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
