import styled, { css } from 'styled-components';
import { media } from 'utils';

const htmlContentFont = css`
  font-size: 18px;
  line-height: 1.5;

  ${media.large(css`
    font-size: 20px;
  `)};
`;

export const Content = styled.div`
  h1 {
    font-size: 32px;

    ${media.large(css`
      font-size: 36px;
    `)};
  }

  h2 {
    font-size: 28px;

    ${media.large(css`
      font-size: 32px;
    `)};
  }

  h3 {
    font-size: 24px;

    ${media.large(css`
      font-size: 28px;
    `)};
  }

  h4 {
    font-size: 20px;

    ${media.large(css`
      font-size: 24px;
    `)};
  }

  h5 {
    font-size: 16px;

    ${media.large(css`
      font-size: 20px;
    `)};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
    line-height: 1.2;

    ${media.large(css`
      margin-bottom: 15px;
    `)};
  }

  p {
    ${htmlContentFont};
    color: ${({ theme }) => theme.color.gray};

    ${media.large(css`
      margin-bottom: 25px;
    `)};
  }

  a {
    color: ${({ theme }) => theme.color.darkGray};

    &:visited {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }

  ul,
  ol {
    margin-left: 30px;
  }

  ul li,
  ol li {
    ${htmlContentFont};
    padding-left: 8px;
    margin-bottom: 10px;
  }

  blockquote {
    padding-left: 20px;
    border-left: 2px solid ${({ theme }) => theme.color.lightGray};
    font-style: italic;
  }

  img {
    max-width: 100%;
  }

  .gatsby-resp-image-link {
    margin-left: -${({ theme }) => theme.layout.gapMobileX};
    margin-right: -${({ theme }) => theme.layout.gapMobileX};
    padding: 15px 0;

    ${media.large(css`
      margin-left: -10vw;
      margin-right: -10vw;
      padding: 30px 0;
    `)};
  }
`;
