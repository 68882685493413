import styled from 'styled-components';

import { animated } from 'react-spring/renderprops';

export const BulletsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const BulletsContent = styled.div`
  display: flex;
`;

export const Bullet = styled(animated.button)`
  border: 0;
  padding: 0;
  width: 9px;
  height: 9px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin-right: 7px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  &:focus {
    outline: none;
  }
`;

export const activeStyles = {
  opacity: 1,
  transform: 'scale(1.2)',
  width: '9px',
};
