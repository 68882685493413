import React from 'react';
import PropTypes from 'prop-types';

import { FluidImagePropTypes } from 'types';
import { ThirdHeader, Description } from 'components/common/Typography';

import { ImageBoxWrapper, Image } from './ImageBoxStyles';

export const ImageBox = ({ description, image, isCircular, isFluid, name }) => (
  <ImageBoxWrapper>
    <Image
      alt={name}
      as={isFluid ? Image : 'img'}
      fluid={image}
      isCircular={isCircular}
      isFluid={isFluid}
      src={image}
    />
    <ThirdHeader isBolded>{name}</ThirdHeader>
    <Description isCentered>{description}</Description>
  </ImageBoxWrapper>
);

ImageBox.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  isCircular: PropTypes.bool,
  isFluid: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

ImageBox.defaultProps = {
  isCircular: false,
  isFluid: false,
};
