import styled, { css } from 'styled-components';
import { media } from 'utils';

import { ImageBoxWrapper } from 'components/common/ImageBox/ImageBoxStyles';
import { columnWidth, GridBoxes } from 'components/common/GridBoxes/GridBoxes';

export const GridWrapper = styled(GridBoxes)`
  ${({ isMultiRow }) =>
    isMultiRow &&
    media.large(css`
      grid-template: repeat(2, auto) / repeat(3, ${columnWidth});
      grid-template-areas:
        '. top .'
        'left middle right';

      ${ImageBoxWrapper}:first-child {
        grid-area: top;
      }
      /* prettier-ignore */
      ${ImageBoxWrapper}:nth-child(2) {
        grid-area: left;
      }
      /* prettier-ignore */
      ${ImageBoxWrapper}:nth-child(3) {
        grid-area: middle;
      }

      ${ImageBoxWrapper}:last-child {
        grid-area: right;
      }
    `)};
`;
