import styled, { css } from 'styled-components';
import { media } from 'utils';

export const PageContent = styled.div`
  max-width: ${(props) => props.theme.layout.contentWidth};
  margin: 0 auto;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-gap: ${({ theme }) => theme.layout.gapBoxesMobile};

  ${media.large(css`
    grid-gap: ${({ theme }) => theme.layout.gapBoxesDesktop};
  `)};
`;
