import React from 'react';

import { LoaderWrapper, Loader } from './BounceLoaderStyles';

export const BounceLoader = () => (
  <LoaderWrapper>
    <Loader item={1} />
    <Loader item={2} />
  </LoaderWrapper>
);
