import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 100% {transform: scale(0)}
  50% {transform: scale(1.0)}
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: ${({ size }) => (size && `${size}px`) || '30px'};
  height: ${({ size }) => (size && `${size}px`) || '30px'};
`;

export const Loader = styled.div`
  position: absolute;
  width: ${({ size }) => (size && `${size}px`) || '30px'};
  height: ${({ size }) => (size && `${size}px`) || '30px'};
  background-color: ${({ color, theme }) => theme.color[color || 'primary']};
  border-radius: 100%;
  opacity: 0.6;
  top: 0;
  left: 0;
  animation-fill-mode: both;
  animation: ${bounce} 2.1s ${({ item }) => (item === 1 ? '1s' : '0s')} infinite
    ease-in-out;
`;
