import React from 'react';
import PropTypes from 'prop-types';

import arrowUrl from 'images/arrow.svg';

import { ArrowButton } from './ArrowStyles';

export const Arrow = ({ alt, isDisabled, isLeft, onClick }) => (
  <ArrowButton isDisabled={isDisabled} isLeft={isLeft} onClick={onClick}>
    <img alt={alt} src={arrowUrl} />
  </ArrowButton>
);

Arrow.propTypes = {
  alt: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLeft: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Arrow.defaultProps = {
  alt: '',
  isDisabled: false,
  isLeft: false,
};
