import React from 'react';
import PropTypes from 'prop-types';

import { Transition } from 'react-spring/renderprops';

import {
  BulletsWrapper,
  BulletsContent,
  Bullet,
  activeStyles,
} from './BulletsStyles';

const generateIndexes = (count, activeIndex) => {
  const indexes = Array.from({ length: count }, (_, index) => index);
  const shouldMoveArray = activeIndex < count - 3;
  const shouldStartMoving = activeIndex > 2;
  const startIndex = shouldMoveArray ? activeIndex - 2 : count - 5;
  const endIndex = shouldMoveArray ? activeIndex + 3 : count;

  if (count <= 5) return indexes;

  return indexes.slice(
    shouldStartMoving ? startIndex : 0,
    shouldStartMoving ? endIndex : 5
  );
};

export const Bullets = ({ activeIndex, count, onClick }) => (
  <BulletsWrapper>
    <BulletsContent>
      <Transition
        enter={{ opacity: 0.8, transform: 'scale(1)', width: 9 }}
        from={{ opacity: 0, transform: 'scale(0)', width: 0 }}
        initial={null}
        items={generateIndexes(count, activeIndex)}
        leave={{ opacity: 0, transform: 'scale(0)', width: 0 }}
        native
      >
        {(index) => (styles) => (
          <Bullet
            aria-label={`Slider navigation ${index + 1}`}
            onClick={() => onClick(index)}
            style={activeIndex === index ? activeStyles : styles}
          />
        )}
      </Transition>
    </BulletsContent>
  </BulletsWrapper>
);

Bullets.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
