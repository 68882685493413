import styled, { css } from 'styled-components';
import { media } from 'utils';
import Img from 'gatsby-image';

export const ImageBoxWrapper = styled.div`
  text-align: center;
`;

export const Image = styled(Img)`
  max-width: 150px;
  border-radius: ${({ isCircular }) => (isCircular ? '50%' : 0)};
  margin-bottom: 20px;
  text-align: center;

  ${({ isFluid }) =>
    isFluid &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `};

  ${media.large(css`
    max-width: 200px;
  `)};
`;
